export interface TimeDisplayProps {
  milliseconds: number;
}

export function TimeDisplay({ milliseconds }: TimeDisplayProps) {
  const getMilliseconds = () => {
    return milliseconds % 1000;
  };

  const getSeconds = () => {
    return Math.floor(milliseconds / 1000) % 60;
  };

  const getMinutes = () => {
    return Math.floor(milliseconds / 1000 / 60) % 60;
  };

  const getHours = () => {
    return Math.floor(milliseconds / 1000 / 60 / 60);
  };

  const getDisplayString = () => {
    const hours = getHours();
    const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    const minutes = getMinutes();
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = getSeconds();
    const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
    const milliseconds = getMilliseconds();
    const millisecondsStr =
      milliseconds < 10
        ? `00${milliseconds}`
        : milliseconds < 100
        ? `0${milliseconds}`
        : `${milliseconds}`;
    return `${hoursStr}:${minutesStr}:${secondsStr}.${millisecondsStr}`;
  };

  return (
    <div className="text-center text-7xl my-10 font-mono">
      {getDisplayString()}
    </div>
  );
}
