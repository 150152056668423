import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

export interface TimerEditDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: (seconds: number) => void;
}

export function TimerEditDialog({
  isOpen,
  onClose,
  onAccept,
}: TimerEditDialogProps) {
  const cancelRef = useRef(null);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Edit Timer</AlertDialogHeader>

          <AlertDialogBody>
            <div className="flex justify-center gap-2 items-center">
              <Input
                htmlSize={4}
                width="auto"
                placeholder="H"
                type="number"
                value={hours}
                onChange={(e) => setHours(Number(e.target.value))}
              />
              <div>:</div>
              <Input
                htmlSize={4}
                width="auto"
                placeholder="M"
                type="number"
                value={minutes}
                onChange={(e) => setMinutes(Number(e.target.value))}
              />
              <div>:</div>
              <Input
                htmlSize={4}
                width="auto"
                placeholder="S"
                type="number"
                value={seconds}
                onChange={(e) => setSeconds(Number(e.target.value))}
              />
            </div>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="blue"
              onClick={() => onAccept(hours * 60 * 60 + minutes * 60 + seconds)}
            >
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
