import { defineStyle, extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      50: "#dcdcdc",
      100: "#b9b9b9",
      200: "#969696",
      300: "#737373",
      400: "#505050",
      500: "#2d2d2d",
      600: "#0a0a0a",
      700: "#000000",
      800: "#000000",
    },
  },
  components: {
    Button: {
      variants: {
        solid: defineStyle({
          borderRadius: 0,
        }),
      },
    },
  },
});
