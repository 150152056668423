import { Button } from "@chakra-ui/react";
import { TimeDisplay } from "../components/TimeDisplay";
import { useEffect, useState } from "react";
import { TimerEditDialog } from "../components/TimerEditDialog";
import { millisecondsToHuman, speak } from "../helpers/speech";

export function TimerPage() {
  const [millisecondsLeft, setMillisecondsLeft] = useState(0);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [startedAt, setStartedAt] = useState<Date | undefined>();
  const [targetEndDate, setTargetEndDate] = useState<Date>(new Date());
  const [lastAnnounced, setLastAnnounced] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (startedAt) {
        const millisecondsLeft = targetEndDate.getTime() - new Date().getTime();
        if (millisecondsLeft > 0) {
          setMillisecondsLeft(targetEndDate.getTime() - new Date().getTime());
          const secondsLeft = Math.floor(millisecondsLeft / 1000);
          if (secondsLeft % 60 === 0 && lastAnnounced !== secondsLeft) {
            setLastAnnounced(secondsLeft);
            speak(millisecondsToHuman(millisecondsLeft) + " remaining");
          }
        } else {
          setMillisecondsLeft(0);
          setStartedAt(undefined);
        }
      }
    }, 1);
    return () => clearInterval(interval);
  }, [startedAt, targetEndDate, lastAnnounced]);

  return (
    <div className="my-4">
      <div className="text-center gap-2 flex justify-center">
        <Button colorScheme={"brand"} onClick={() => setIsEditDialogOpen(true)}>
          Edit
        </Button>
        <Button
          colorScheme="brand"
          onClick={() => {
            if (!startedAt) {
              setStartedAt(new Date());
              setTargetEndDate(
                new Date(new Date().getTime() + millisecondsLeft)
              );
            } else {
              setStartedAt(undefined);
            }
          }}
        >
          {startedAt ? "Pause" : "Start"}
        </Button>
      </div>
      <TimeDisplay milliseconds={millisecondsLeft} />
      <TimerEditDialog
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onAccept={(seconds) => {
          setMillisecondsLeft(seconds * 1000);
          setIsEditDialogOpen(false);
        }}
      ></TimerEditDialog>
    </div>
  );
}
