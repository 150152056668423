export function millisecondsToHuman(ms: number) {
  const hours = Math.floor(ms / 1000 / 60 / 60);
  const minutes = Math.floor(ms / 1000 / 60) % 60;
  const seconds = Math.floor(ms / 1000) % 60;

  if (hours === 0 && minutes === 0) {
    return `${seconds} seconds`;
  }
  if (hours === 0) {
    return `${minutes} minutes, ${seconds} seconds`;
  }

  return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
}

export function speak(sentence: string) {
  const synth = window.speechSynthesis;
  const utter = new SpeechSynthesisUtterance(sentence);
  synth.speak(utter);
}
