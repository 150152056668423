import { Route, Routes, Navigate } from "react-router-dom";
import { TimerPage } from "./pages/Timer";
import { MainLayout } from "./layouts/MainLayout";
import { AboutPage } from "./pages/About";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Navigate to="/timer" replace />} />
          <Route path="timer" element={<TimerPage />} />
          <Route path="about" element={<AboutPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
