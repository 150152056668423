import { Tab, TabList, Tabs } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";

export function MainLayout() {
  const navigate = useNavigate();

  return (
    <div className="max-w-5xl mx-auto my-5">
      <Tabs align="center" colorScheme="brand">
        <TabList>
          <Tab onClick={() => navigate("/timer")}>Timer</Tab>
          <Tab onClick={() => navigate("/about")}>About</Tab>
        </TabList>
      </Tabs>
      <Outlet />
    </div>
  );
}
